import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'
import { mobile, tablet, Input } from '~styles/global'
import sendToMailchimp from '../utils/sendToMailchimp'
import { Collapse } from 'react-collapse'
import useSiteSettings from '~utils/useSiteSettings'
import { useEffect } from 'react'
import { subscribe } from 'klaviyo-subscribe'
import { Cross } from '~components/Svg'

const Subscribe = ({ className , white, close, open, onClose, focusOnOpen, noTitle }) => {

	const [formOpen, setFormOpen] = useState(false)
	const listId = 'TupEVH'

	const { newsletterSignUpTitle, newsletterSuccessMessage } = useSiteSettings()

	const [values, setValues] = useState({})
	const [errors, setErrors] = useState([])
	const [success, setSuccess] = useState(false)
	const emailRef = useRef()

	const validate = e => {
		e.preventDefault()
		const newErrors = []

		if(!values.fname) newErrors.push({
			field: 'fname',
			message: 'Your first name is required'
		})

		if(!values.lname) newErrors.push({
			field: 'lname',
			message: 'Your last name is required'
		})

		if(!values.email) newErrors.push({
			field: 'email',
			message: 'The email field is required'
		})

		if(values.email && (!values.email.includes('@') || !values.email.includes('.'))) newErrors.push({
			field: 'email',
			message: 'Please check your email address'
		})

		if(newErrors.length === 0){
			const form = e.currentTarget
			const { email } = form.elements

			subscribe(listId, email.value, {
				$first_name: values.fname,
				$last_name: values.lname
			})
				.then(() => {
					setFormOpen(false)
					setSuccess(true)
				})
		}
		else{
			setErrors(newErrors)
		}
	}

	const errorFields = errors.map(error => error.field)

	const openOverlay = () => {
		setFormOpen(true)
		emailRef.current.focus()
	}

	useEffect(() => {
		if (open){
			setFormOpen(true)
		}	
	}, [open])

	useEffect(() => {
		if(focusOnOpen && formOpen) {
			emailRef.current.focus()
		}
	}, [focusOnOpen, formOpen])

	return (
		<>
			<Wrap className={className} noTitle={noTitle}>
				<Title className='h4'>
					<div>{!noTitle && newsletterSignUpTitle}</div>
					<Close onClick={() => onClose ? onClose() : setFormOpen(false)} show={formOpen || (onClose && success)}>
						<Cross />
					</Close>
				</Title>
				<FormWrap >
					<Form onSubmit={e => validate(e)} noValidate>
						<Collapse isOpened={formOpen}>
							<Input
								type="text"
								className='h4'
								name="fname"
								placeholder="First Name*"
								error={errorFields.includes('fname')}
								onChange={e => {
									setValues({...values, fname: e.target.value})
									setErrors(prevState => prevState.filter(error => error.field !== 'fname'))
								}}
								white={white}
							/>
							<Input
								type="text"
								className='h4'
								name="lname"
								placeholder="Last Name*"
								error={errorFields.includes('lname')}
								onChange={e => {
									setValues({...values, lname: e.target.value})
									setErrors(prevState => prevState.filter(error => error.field !== 'lname'))
								}}
								white={white}
							/>
							<Input
								type="text"
								className='h4'
								name="company"
								placeholder="Company"
								onChange={e => setValues({...values, lname: e.target.value})}
								white={white}
							/>
						</Collapse>
						<TriggerContainer white={white} red={errorFields.includes('email')}>
							<OpenOverlay onClick={() => openOverlay()} formOpen={formOpen} success={success}/>
							<Email
								ref={emailRef}
								type="email"
								className='h4'
								name="email"
								placeholder="Enter your email*"
								error={errorFields.includes('email')}
								onChange={e => {
									setValues({...values, email: e.target.value})
									setErrors(prevState => prevState.filter(error => error.field !== 'email'))
								}}
								success={success}
								white={white}
							/>
							<Success success={success}>
								{newsletterSuccessMessage}
							</Success>
							<SubscribeButton type="submit" className='h4' success={success}>Subscribe</SubscribeButton>
						</TriggerContainer>
					</Form>
				</FormWrap>
			</Wrap>
		</>
	)
}

const Wrap = styled.div`
	margin-top: ${props => props.noTitle ? '2vw' : '3vw'};
`
const Title = styled.div`
	margin-bottom: 1vw;
	display: flex;
	justify-content: space-between;
	width: 100%;
`
const FormWrap = styled.div`
	position: relative;
`
const Form = styled.form`
`
const Email = styled(Input)`
	opacity: ${props => props.success ? '0' : '1'};
	pointer-events: ${props => props.success ? 'none' : 'inherit'};
	border-bottom: none;
	&:-webkit-autofill{
		border-bottom: none;
		:hover{
			border-bottom: none;
		}
	}
`
const Success = styled.h5`
  transition: opacity 0.3s ;
	opacity: ${ props => props.success ? '1' : '0'};
  pointer-events: ${ props => props.success ? 'inherit' : 'none'};
	position: absolute;
	left: 0;
	bottom: 0.1em;
`
const SubscribeButton = styled.button`
	position: absolute;
	right: 0;
	bottom: 0.35em;
	opacity: ${props => props.success ? '0' : '1'};
	pointer-events: ${props => props.success ? 'none' : 'inherit'};
	${tablet}{
		bottom: 0.24em;
	}
	${mobile}{
		bottom: 0.18em;
	}
`
const TriggerContainer = styled.div`
	position: relative;	
	border-bottom: ${props => props.red ? '1px solid var(--red)' : props.white ? '1px solid var(--white)' : '1px solid var(--black)'};
`
const OpenOverlay = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	pointer-events: ${props => (props.formOpen || props.success) ? 'none' : 'inherit'};
	cursor: pointer;
`
const Errors = styled.div`

`
const Close = styled.button`
	width: 10px;
	display: ${props => props.show ? 'block' : 'none'};
	height: 10px;
	position: relative;
	top: 0.3em;
	>svg{
		display: block;
		width: 0.45rem;
	}
`

Subscribe.propTypes = {
	className: PropTypes.string,
	white: PropTypes.bool,
	close: PropTypes.bool,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	focusOnOpen: PropTypes.bool,
	onSubscribe: PropTypes.func,
	noTitle: PropTypes.bool
}

export default Subscribe